import React from 'react';
import header from "../assets/tussenstopHeader.jpg";
import "../styles/home.css"
// import kapsalon from "../assets/kapsalon.webp"
import { mdiClockOutline } from '@mdi/js';
import Icon from '@mdi/react';

function Home() {
    return (
        <div>
            <div className={"imageContainer"}>
                <img className={"headerImage"} src={header} alt={"whiteLogo"}/>
                <div className="text-overlay">
                    <h1 className={"naamKapsalon"}>De Tussenstop</h1>
                </div>
            </div>

            <div className={"introContainer"}>
                <div className={"textContainer"}>
                    <p className={"vetteTekst"}>
                        Welkom op de website van kapsalon De Tussenstop!
                    </p>
                    <p>Na jaren lange ervaring in het kappersvak en meerdere functies te hebben gehad, ben ik in 2014 voor mezelf begonnen.
                        Nu sinds 30 Oktober 2023 mijn nieuwe uitdaging met kapsalon "De Tussenstop".
                        Nieuwe naam, nieuwe plek, nieuw logo, nieuwe website, een trots moment in mijn carrière.
                    </p>
                    <p> </p>

                </div>
                <div className={"openingContainer"}>
                    <div className={"openingsTijden"}>
                        <div className={"openingstijdenNaam"}>
                            <Icon className={"openingstijdenKlok"} size={1} path={mdiClockOutline}></Icon>
                            <h2 className={"openingstijdenText"}>Openingstijden</h2>
                            <p className={"tijd"}>Maandag     09:30-17:30</p>
                            <p className={"tijd"}>Dinsdag     13:00-20:30</p>
                            <p className={"tijd"}>Woensdag     09:30-15:00</p>
                            <p className={"tijd"}>Donderdag     09:30-17:30</p>
                            <div className={"zaterdagContainer"}>
                            <i>Eerste en laatste zaterdag van de maand:</i>
                            <p className={"tijd"}>Zaterdag     09:00-13:00</p>
                            </div>
                        </div>
                        <div className={"andereDiv"}></div>
                    </div>
                </div>

            </div>


        </div>
    );
}

export default Home;
