import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from "./pages/home";
import About from "./pages/about";
import Prijzen from "./pages/prijzen";
import Navbar from "./components/navbar";
import Nieuws from "./pages/nieuws";
import Producten from "./pages/producten";
import MobileMenu from "./components/navbarPhone";

// Check if the user agent indicates a mobile device
function isMobileDevice() {
    return /Mobi|Android|iPhone|iPad|iPod|Windows Phone|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

//todo letop basename veranderd
function App() {
    const showNavbar = !isMobileDevice(); // Determine whether to show the Navbar component

    return (
        <Router>
            {showNavbar && <Navbar />}
            {!showNavbar && <MobileMenu />}
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/over" element={<About />} />
                <Route path="/prijzen" element={<Prijzen />} />
                <Route path="/nieuws" element={<Nieuws />} />
                <Route path="/producten" element={<Producten />} />
            </Routes>
        </Router>
    );
}

export default App;
