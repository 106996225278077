import React, { useRef, useEffect } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import '../styles/map.css';


const MapComponent = () => {
    const mapContainer = useRef(null);
    const mapInstance = useRef(null);

    useEffect(() => {
        if (!mapInstance.current) {
            const map = L.map(mapContainer.current).setView([52.081705, 5.015224], 15);

            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            }).addTo(map);

            // Create a custom icon using AwesomeMarkers
            const customIcon = L.divIcon({
                className: 'custom-icon', // Add a class for styling
                html: ''
            });

            const markerCoords = [52.082500, 5.019100];
            L.marker(markerCoords, { icon: customIcon }).addTo(map)
                .bindPopup('Van Lawick van Pabstlaan 12, De Meern')
                .openPopup();

            mapInstance.current = map;
        }

        return () => {
            if (mapInstance.current) {
                mapInstance.current.remove();
                mapInstance.current = null;
            }
        };
    }, []);

    return <div ref={mapContainer} className="map-container"></div>;
};

export default MapComponent;
