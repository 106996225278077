import React from 'react';
import header from "../assets/tussenstopHeader.jpg";
import portretPhoto from "../assets/foto mama.jpeg";
import photoVoorkant from "../assets/foto voorkant.jpeg"
import "../styles/about.css"
import MapComponent from "../components/map";

function About() {
    return (
        <div notranslate>
            <div className={"imageContainer"}>
                <img className={"headerImage"} src={header} alt={"whiteLogo"}/>
                <div className="text-overlay">
                    <h2>Over mij</h2>
                </div>
            </div>

            <div className={"upperHalfContainer"}>
                <div className={"photoContainer"}>
                    <img className={"portretPhoto"} src={portretPhoto} alt={"PhotoJacintha"}/>
                </div>
                <div className={"textContainer"}>
                    <h2 className={"headerText"}>Jacintha Bernaert</h2>
                    <div classname={"upperUnderContainer"}>
                    Ik zal mijzelf voorstellen, ik ben Jacintha Bernaert.
                    <br/>
                    Ik ben een gezellige en enthousiaste en zeer gepassioneerde kapster.
                    <br/>
                    Mijn klanten leg ik graag in de watten.
                    <br/>
                    Vandaar dat de naam van de kapsalon "De Tussenstop" is geworden en daarbij het bijpassende motto: "die jij jezelf gunt".
                    <br/>
                    Door mijn jaren lange ervaring beheers ik over alle moderne knip- en kleur technieken.
                    </div>
                    <div className={"underContainer"}>
                    Tijdens de openingstijden ben ik telefonisch bereikbaar op 0642806010
                    <br/>
                    En als je mailt om een afspraak te maken op salondetussenstop@gmail.com krijg je altijd heel snel een antwoord!
                    <br/>
                    Tot ziens in de salon.</div>
                </div>
            </div>

            <div className={"bottomHalfContainer"}>
                <div className={"textContainerKapsalon"}>
                    <div className={"bottomText"}>
                        <h2 className={"headerTextKapsalon"}>Kapsalon De Tussenstop</h2>
                        <p>Van Lawick van Pabstlaan 12 3453 RB De Meern
                            <br/> <br/>
                            Mail salondetussenstop@gmail.com of bel 0642806010 om een afspraak te maken.
                            <br/> <br/>
                            Wij zijn te vinden in de wijk Veldhuizen.
                            <br/>
                            De ingang is bij de tandartspraktijk Veldhuizen
                        </p>
                        <div className={"mapContainer"}>
                            <MapComponent></MapComponent><
                        /div>
                    </div>
                </div>
                <div className={"photoContainerKapsalon"}>
                <img className={"kapsalonPhoto"} src={photoVoorkant} alt={"PhotoJacintha"}/>
            </div>
            </div>

        </div>


    );
}

export default About;
