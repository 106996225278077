import React from 'react';
function Producten() {
    return (
        <div>

            <h1>Producten</h1>
        </div>
    );
}

export default Producten;
