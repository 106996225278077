import React from 'react';
import header from "../assets/tussenstopHeader.jpg";
import "../styles/nieuws.css"
import kapsalon from "../assets/foto news.jpeg"
import springBreak from "../assets/Zomer.jpeg"
function Nieuws() {
    return (
        <div>
            <div className={"imageContainer"}>
                <img className={"headerImage"} src={header} alt={"whiteLogo"}/>
                <div className="text-overlay">
                    <h2>Nieuws</h2>
                </div>
            </div>
            <div>
                {/*Geen nieuws op het moment.*/}
                <div className="listContainer">
                    <div className="list-item">
                        <div className={"border"}>
                            <img className={"imgClass"} src={kapsalon} alt={"plaatje kapsalon"}/>
                            <h1>Opening kapsalon</h1>
                            <p>Vandaag de opening van de nieuwe zaak. Maandag 30 oktober worden de eerste klanten hier geknipt. Een nieuwe uitdaging. Een mooi begin.</p>
                        </div>
                    </div>
                    <div className="list-item">
                        <div className={"border"}>
                            <img className={"imgClass"} src={springBreak} alt={"plaatje kapsalon"}/>
                            <h1>Vakantie 8 juli tm 29 juli</h1>
                            <p>Ik heb vakantie van 8 juli tot en met 29 juli.</p>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
}

export default Nieuws;
