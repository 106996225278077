import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { mdiMenu } from '@mdi/js';
import Icon from '@mdi/react';
import '../styles/navbarphone.css';

const MobileNavigation = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <nav className={`mobile-nav ${menuOpen ? 'open' : ''}`}>
            <div className="menu-icon" onClick={toggleMenu}>
                <Icon path={mdiMenu} size={1.4} />
            </div>
            <ul className="mobile-nav-list">
                <li className="mobile-nav-item">
                    <Link to="/">Home</Link>
                </li>
                <li className="mobile-nav-item">
                    <Link to="/nieuws">Nieuws</Link>
                </li>
                <li className="mobile-nav-item">
                    <Link to="/prijzen">Prijzen</Link>
                </li>
                {/*<li className="mobile-nav-item">*/}
                {/*    <Link to="/producten">Producten</Link>*/}
                {/*</li>*/}
                <li className="mobile-nav-item">
                    <Link to="/over">Over</Link>
                </li>
            </ul>
        </nav>
    );
};

export default MobileNavigation;
