import React from 'react';
import {Link} from 'react-router-dom';
import logo from '../assets/logo.png'
import '../styles/navbar.css'
import Icon from '@mdi/react';
import { mdiEmail, mdiPhone } from '@mdi/js';


function Navbar() {
    function clickLogo(){

        const currentURL = window.location.href;
        const pathParts = currentURL.split('/').filter(part => part !== '');
        const lastPathname = pathParts[pathParts.length - 1];
        //todo letop veranderd
        if(lastPathname !== "de-tussenstop")
        window.location.href = currentURL.replace(lastPathname, '')
    }

    return (
        <div className={"contentDiv"}>
            <div className={"logoContainer"} >
                <img onClick={clickLogo} className={"logo"} src={logo} alt={"whiteLogo"}/>
            </div>
            <div className={"linksContainer"}>
                <Link to="/nieuws">Nieuws</Link>
                <br/>
                <Link to="/prijzen">Prijzen</Link>
                <br/>
                {/*<Link to="/producten">Producten</Link>*/}
                {/*<br/>*/}
                <Link to="/over">Over</Link>
                <br/>

            </div>
            <div className={"contactDiv"}>
                <a href={"mailto:salondetussenstop@gmail.com"} className={"mailContainer"}>
                    <Icon className={"icon"} path={mdiEmail} size={1} />
                    <p className={"iconText"}>Salondetussenstop@gmail.com</p>
                </a>
                <a className={"phoneContainer"} href={"tel:0642806010"}>
                    <Icon className={"icon"} path={mdiPhone} size={1} />
                    <p className={"iconText"}>06-42806010</p>
                </a>

            </div>
        </div>
    );
}

export default Navbar;
